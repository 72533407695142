.awards {
    background-image: url("../../../../Assets/awardBg.png");
    background-size: cover;
    background-color: #424f87;
    background-repeat: no-repeat;
    padding: 12rem 0;
    color: white;
}
.awards__title {
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
    padding-bottom: 2rem;
    z-index: 2;
    text-transform: uppercase;
}
.awards__container {
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 124rem;
    padding: 0 2rem;
    margin: 0 auto;
}
.awards__box {
    -webkit-box-shadow: 0.5rem 0.5rem 1rem 0.2rem rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0.5rem 0.5rem 1rem 0.2rem rgba(0, 0, 0, 0.2);
    box-shadow: 0.5rem 0.5rem 1rem 0.2rem rgba(0, 0, 0, 0.2);
    z-index: 2;
    background-color: #36416f;
    border: 1.5rem solid #525e9d;
    margin-right: 5%;
    margin-top: 4rem;
    width: 30%;
    position: relative;
}
.awards__box--laurel {
    position: absolute;
    z-index: -1;
    max-width: 30%;
    height: auto;
    margin: 2rem auto;
    left: 35%;
    right: 35%;
}
.awards__box:nth-of-type(3n) {
    margin-right: 0;
}
.awards__box--header {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 300;
    padding: 3rem 0.5rem 1.5rem 0.5rem;
}
.awards__box--title {
    padding: 0 2rem 0.5rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 8rem;
    font-size: 2rem;
}
.awards__box--text {
    border-bottom: 0.1rem solid #525e9d;
    border-top: 0.1rem solid #525e9d;
    margin: 0 auto 4rem auto;
    text-align: center;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    min-height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 80%;
}
@media screen and (max-width: 991px) {
    .awards__box {
        width: 45%;
        margin-right: unset;
    }
    .awards__box:nth-of-type(3n) {
        margin-right: unset;
    }
    .awards__box:nth-of-type(2n) {
        margin-left: 10%;
    }
}
@media screen and (max-width: 576px) {
    .awards__box {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }
    .awards__box:nth-of-type(3n) {
        margin-right: auto;
        margin-left: auto;
    }
    .awards__box:nth-of-type(2n) {
        margin-right: auto;
        margin-left: auto;
    }
}
