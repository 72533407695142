.request-for-quotation {
  background-color: #73ad60;
  color: white;
  padding: 1rem;
  font-weight: bold;
  margin: 4rem auto 0;
  max-width: 30rem;
  display: block;
  text-align: center;
  transition: 0.3s all;
  font-size: 1.4rem;
}
.request-for-quotation:hover {
  background-color: #82b671;
  color: white;
}
