.contact-container {
    padding: 15rem 2rem 24rem 2rem;
}
.contact-container .contact {
    background-color: #edf6ff;
    padding: 4rem 7rem;
    color: #424f87;
    margin: 0 auto;
    max-width: 140rem;
}
.contact-container .contact__title {
    width: 100%;
    text-align: left;
    font-size: 3rem;
    font-weight: 700;
    margin-bottom: 3rem;
    text-transform: uppercase;
}
.contact-container .contact__title-small {
    font-size: 2.2rem;
    font-weight: 700;
    display: block;
    margin-bottom: 0.5rem;
}
.contact-container .contact__possibilities {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1.6rem;
    font-weight: 600;
    padding-bottom: 8rem;
    border-bottom: 0.1rem solid #c9e4ff;
    margin-bottom: 4rem;
}
.contact-container .contact__possibilities--email {
    min-width: 35%;
}
.contact-container .contact__possibilities--email .email {
    display: block;
}
.contact-container .contact__possibilities--linkedin {
    min-width: 65%;
}
.contact-container .contact__possibilities--linkedin .link {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}
.contact-container .contact__possibilities--linkedin .link:hover {
    color: #525e9d;
}
.contact-container .contact__cards {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 110rem;
}
.contact-container .contact__card {
    margin-bottom: 3rem;
    background-color: white;
    width: 30rem;
    margin: 0 3rem 3rem 3rem;
}
.contact-container .contact__card--header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem 2.5rem 4rem 2.5rem;
    background-color: #424f87;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-weight: 700;
    text-align: center;
    height: 12.3rem;
    color: white;
}
.contact-container .contact__card--body {
    padding: 2rem;
    background-color: white;
}
.contact-container .contact__card--body .icon {
    width: 48px;
    height: 48px;
    display: block;
    margin: -5rem auto 2rem auto;
}
.contact-container .contact__card--body .__address {
    font-size: 1.4rem;
    font-weight: 600;
    max-width: 20rem;
    margin-bottom: 2rem;
    color: #2a325e;
}
.contact-container .contact__card--body .__block {
    margin-bottom: 1.5rem;
}
.contact-container .contact__card--body .__block--title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #525e9d;
}
.contact-container .contact__card--body .__block--text {
    font-size: 1.4rem;
    font-weight: 600;
    color: #2a325e;
}
.contact-container .contact__card--body .__block .link:hover {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #525e9d;
}
.contact-container .contact__map-container {
    width: 100%;
    text-align: center;
}
.contact-container .contact__map-container .map-image {
    max-width: 100%;
    height: auto;
}
@media screen and (max-width: 991px) {
    .contact-container .contact {
        padding: 4rem 2rem;
    }
    .contact-container .contact__card {
        margin: 0 auto 3rem auto;
    }
}
@media screen and (max-width: 680px) {
    .contact-container .contact__cards {
        justify-content: center;
    }
}
@media screen and (max-width: 576px) {
    .contact-container .contact__card--header {
        font-size: 1.5rem;
    }
}
