.back-button {
  background-color: #2a325e;
  color: white;
  padding: 1rem 3rem;
  position: absolute;
  top: -4rem;
  left: 0rem;
  font-size: 1.5rem;
  border: none;
  cursor: pointer;
}
