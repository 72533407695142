.spinner {
    z-index: 10000;
    width: 100%;
    text-align: center;
}
.spinner .spinner-child {
    font-size: 1.2rem;
    font-weight: 600;
    max-width: 30rem;
    margin: 0 auto;
    color: #424f87;
}
.spinner .lds-dual-ring {
    display: inline-block;
    width: 4.6rem;
    height: 4.6rem;
}
.spinner .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 4.6rem;
    height: 4.6rem;
    border-radius: 50%;
    border: 0.5rem solid #424f87;
    border-color: #424f87 transparent #424f87 transparent;
    -webkit-animation: lds-dual-ring 1.2s linear infinite;
    -moz-animation: lds-dual-ring 1.2s linear infinite;
    -ms-animation: lds-dual-ring 1.2s linear infinite;
    -o-animation: lds-dual-ring 1.2s linear infinite;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.tiny-spinner .spinner-child {
    display: none;
}
.tiny-spinner .lds-dual-ring {
    width: 2rem;
    height: 2rem;
}
.tiny-spinner .lds-dual-ring:after {
    width: 1.4rem;
    height: 1.4rem;
    border-width: 0.3rem;
}
.large-spinner .spinner-child {
    font-size: 2rem;
}
.large-spinner .lds-dual-ring {
    width: 16rem;
    height: 16rem;
}
.large-spinner .lds-dual-ring:after {
    width: 16rem;
    height: 16rem;
    border-width: 2.5rem;
}
.absolute-spinner {
    position: absolute;
    left: 0;
    right: 0;
}
.negated-spinner .spinner-child {
    color: #424f87;
}
.negated-spinner .lds-dual-ring:after {
    border-color: white transparent white transparent;
}
