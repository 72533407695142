.header-image {
    background-image: url("../../../../Assets/subpageHeader.png");
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 50rem;
}
.footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    font-size: 1.4rem;
    background-image: -moz-linear-gradient(45deg, #424f87 0%, #2a325e 100%, #2a325e 100%);
    background-image: -webkit-gradient(45deg, #424f87 0%, #2a325e 100%, #2a325e 100%);
    background-image: -webkit-linear-gradient(45deg, #424f87 0%, #2a325e 100%, #2a325e 100%);
    background-image: -o-linear-gradient(45deg, #424f87 0%, #2a325e 100%, #2a325e 100%);
    background-image: -ms-linear-gradient(45deg, #424f87 0%, #2a325e 100%, #2a325e 100%);
    background-image: linear-gradient(45deg, #424f87 0%, #2a325e 100%, #2a325e 100%);
}
.footer .top {
    padding-top: 2rem;
    font-size: 1.6rem;
    font-weight: 300;
}
.footer .bottom .logo-img {
    margin-top: 2rem;
    max-width: 17rem;
    height: auto;
}
