.location-card-component {
    width: 50%;
    padding: 2rem;
    display: inline-flex;
    align-items: flex-start;
    flex-wrap: wrap;
}
.location-card-component .location-card__body {
    position: relative;
    background-color: #2a325e;
    padding: 3rem;
    width: 100%;
    margin-top: -0.1rem;
}
.location-card-component .location-card__icon {
    position: absolute;
    top: -3rem;
    left: 2rem;
    width: 6rem;
    height: auto;
}
.location-card-component .location-card__image-container {
    background-color: #2a325e;
    min-width: 30rem;
    height: auto;
}
.location-card-component .location-card__image {
    height: auto;
    max-width: 100%;
}
.location-card-component .location-card__title {
    margin-top: 0;
    color: #b3d0ea;
    font-size: 1.6rem;
    padding-top: 1rem;
    margin-bottom: 0;
    min-height: 6rem;
}
