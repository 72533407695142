.main-page-component {
  color: #424f87;
  padding-bottom: 24rem;
}
.main-page-component .main-page-header-section__container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15rem 0 10rem 0;
  -webkit-transition: padding 0.3s linear;
  -moz-transition: padding 0.3s linear;
  -ms-transition: padding 0.3s linear;
  -o-transition: padding 0.3s linear;
  transition: padding 0.3s linear;
}
.main-page-component .main-page-header-section__container--left {
  padding-right: 5rem;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 70rem;
}
.main-page-component .main-page-header-section__container--right {
  display: inline-flex;
  flex-direction: column;
  padding-left: 15rem;
  max-width: 40%;
}
.main-page-component .main-page-header-section__container--right img {
  max-width: 100%;
  height: auto;
}
.main-page-component .main-page-header__title {
  font-weight: 600;
  font-size: 5rem;
  text-transform: uppercase;
  margin-bottom: 0;
  -webkit-transition: font-size 0.3s linear;
  -moz-transition: font-size 0.3s linear;
  -ms-transition: font-size 0.3s linear;
  -o-transition: font-size 0.3s linear;
  transition: font-size 0.3s linear;
}
.main-page-component .main-page-header__subtitle {
  font-weight: 600;
  font-size: 4rem;
  text-transform: uppercase;
  margin: 0;
  -webkit-transition: font-size 0.3s linear;
  -moz-transition: font-size 0.3s linear;
  -ms-transition: font-size 0.3s linear;
  -o-transition: font-size 0.3s linear;
  transition: font-size 0.3s linear;
}
.main-page-component .main-page-header__desc {
  font-weight: 600;
  font-size: 2rem;
  text-transform: uppercase;
  margin: 0 0 4rem 0;
  -webkit-transition: font-size 0.3s linear;
  -moz-transition: font-size 0.3s linear;
  -ms-transition: font-size 0.3s linear;
  -o-transition: font-size 0.3s linear;
  transition: font-size 0.3s linear;
}
.main-page-component .main-page-header__introduction {
  font-size: 2rem;
  -webkit-transition: font-size 0.3s linear;
  -moz-transition: font-size 0.3s linear;
  -ms-transition: font-size 0.3s linear;
  -o-transition: font-size 0.3s linear;
  transition: font-size 0.3s linear;
}
.main-page-component .main-page-header__introduction span {
  font-weight: 600;
}
.main-page-component .main-page-header__button-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 6rem;
}
.main-page-component .main-page-header__button-container .hash-link {
  padding: 1rem 2rem;
  font-size: 1.4rem;
  border: 0.2rem solid #424f87;
  -webkit-transition: background-color 0.2s linear;
  -moz-transition: background-color 0.2s linear;
  -ms-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}
.main-page-component .main-page-header__button-container .hash-link:hover {
  background-color: #424f87;
  color: white;
}
.main-page-component .main-page-header__button-container .hash-link:first-of-type {
  margin-right: 1rem;
}
.main-page-component .main-page-ventures-section {
  background-color: #2a325e;
  display: flex;
  color: white;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 876px) {
  .main-page-component .main-page-ventures-section {
    background-image: none !important;
  }
}
@media only screen and (max-width: 1500px) {
  .main-page-component .main-page-ventures-section {
    background-position-x: -30rem;
  }
}
@media only screen and (max-width: 1200px) {
  .main-page-component .main-page-ventures-section {
    background-position-x: -59rem;
  }
}
.main-page-component .main-page-ventures-section__left {
  flex: 0 0 50%;
}
@media only screen and (max-width: 876px) {
  .main-page-component .main-page-ventures-section__left {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  .main-page-component .main-page-ventures-section__left {
    flex: 0 0 30%;
  }
}
.main-page-component .main-page-ventures-section__right {
  flex: 0 0 50%;
  padding: 8rem 10rem 8rem;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  .main-page-component .main-page-ventures-section__right {
    flex: 0 0 70%;
  }
}
@media only screen and (max-width: 876px) {
  .main-page-component .main-page-ventures-section__right {
    flex: 0 0 100%;
    padding: 4rem 5rem 4rem;
  }
}
@media only screen and (max-width: 576px) {
  .main-page-component .main-page-ventures-section__right {
    padding: 4rem 2rem 4rem;
  }
}
.main-page-component .main-page-ventures-section h1,
.main-page-component .main-page-ventures-section h2 {
  font-weight: 600;
  font-size: 3rem;
  text-transform: uppercase;
  margin-bottom: 3rem;
  -webkit-transition: font-size 0.3s linear;
  -moz-transition: font-size 0.3s linear;
  -ms-transition: font-size 0.3s linear;
  -o-transition: font-size 0.3s linear;
  transition: font-size 0.3s linear;
}
.main-page-component .main-page-ventures-section h2 {
  font-size: 2rem;
}
.main-page-component .main-page-ventures-section p {
  font-size: 1.6rem;
  text-align: justify;
  line-height: 2;
  margin-bottom: 6rem;
}
.main-page-component .main-page-ventures-section__link-label {
  font-size: 1.6rem;
  margin-top: 2rem;
}
.main-page-component .main-page-ventures-section button {
  border: none;
  background-color: #424f87;
  padding: 2rem 5rem;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
}
.main-page-component .main-page-ventures-section button:hover {
  background-color: #4a5998;
}
@media screen and (max-width: 576px) {
  .main-page-component .main-page-ventures-section button {
    padding: 2rem;
    font-size: 1.3rem;
  }
}
.main-page-component .main-page-map-with-markers {
  display: none;
  max-width: 100%;
  height: auto;
}
.main-page-component .main-page-location-section {
  background-color: #edf6ff;
}
.main-page-component .main-page-location-section__title {
  text-transform: uppercase;
  font-size: 3rem;
  padding-top: 10rem;
  padding-bottom: 0;
  margin-bottom: 0;
  text-align: center;
  margin-top: 0;
}
.main-page-component .main-page-location-columns {
  display: flex;
  position: relative;
}
.main-page-component .main-page-location-columns__right {
  width: 50%;
  text-align: right;
  position: relative;
}
.main-page-component .main-page-location-columns__left {
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-self: center;
  padding-left: 10rem;
}
.main-page-component .main-page-location-map {
  margin: 0 0 0 auto;
  width: 100%;
  height: 100%;
}
.main-page-component .map-marker {
  position: absolute;
}
.main-page-component .main-page-location-marker-1 {
  top: 37%;
  left: 16%;
}
.main-page-component .main-page-location-marker-2 {
  top: 32%;
  left: 44.5%;
}
.main-page-component .main-page-location-marker-3 {
  top: 57.5%;
  left: 37%;
}
.main-page-component .main-page-location-marker-4 {
  top: 66%;
  left: 48%;
}
.main-page-component .add-marker-shaking {
  -webkit-animation: bounce-element 0.5s linear infinite;
  -moz-animation: bounce-element 0.5s linear infinite;
  -ms-animation: bounce-element 0.5s linear infinite;
  -o-animation: bounce-element 0.5s linear infinite;
  animation: bounce-element 0.5s linear infinite;
}
@media screen and (max-width: 1700px) {
  .main-page-component .main-page-header-section__container {
    align-items: center;
    justify-self: center;
    padding: 10rem 2rem 15rem 2rem;
  }
  .main-page-component .main-page-header-section__container--left {
    padding: 0;
    max-width: 50rem;
  }
}
@media screen and (max-width: 1600px) {
  .main-page-component .main-page-location-columns__left {
    padding-left: 0;
  }
}
@media screen and (max-width: 1366px) {
  .main-page-component .main-page-location-columns__left {
    max-width: 60rem;
  }
  .main-page-component .main-page-location-columns__left .location-card-component {
    max-width: 30rem;
    width: 100%;
  }
  .main-page-component .main-page-location-columns__left .location-card-component .location-card__image-container {
    width: 100%;
    min-width: unset;
  }
  .main-page-component .main-page-location-columns__left .location-card-component .location-card__body {
    min-height: 13.6rem;
  }
}
@media screen and (max-width: 1290px) {
  .main-page-component .main-page-header-section__container--right {
    padding-left: 5rem;
  }
  .main-page-component .main-page-location-columns {
    flex-wrap: wrap;
  }
  .main-page-component .main-page-location-columns__left {
    width: 100%;
    max-width: unset;
  }
  .main-page-component .main-page-location-columns__left .location-card-component {
    max-width: 50%;
  }
  .main-page-component .main-page-location-columns__right {
    width: 100%;
    text-align: center;
  }
  .main-page-component .main-page-location-columns__right .main-page-location-map {
    max-width: 80%;
    margin: 0 auto;
  }
  .main-page-component .main-page-location-columns__right .main-page-location-marker-1 {
    left: 23.5%;
  }
  .main-page-component .main-page-location-columns__right .main-page-location-marker-2 {
    left: 46%;
  }
  .main-page-component .main-page-location-columns__right .main-page-location-marker-3 {
    left: 40%;
  }
  .main-page-component .main-page-location-columns__right .main-page-location-marker-4 {
    top: 65.5%;
    left: 49%;
  }
}
@media screen and (max-width: 1200px) {
  .main-page-component .main-page-header-section__container {
    padding: 10rem 2rem 10rem 2rem;
  }
  .main-page-component .main-page-header-section__container--left {
    min-width: 60rem;
  }
  .main-page-component .main-page-header-section__container--right {
    padding-left: 0;
  }
  .main-page-component .main-page-header__title {
    font-size: 4rem;
  }
  .main-page-component .main-page-header__subtitle {
    font-size: 3rem;
  }
  .main-page-component .main-page-header__desc {
    font-size: 1.6rem;
  }
  .main-page-component .main-page-header__introduction {
    font-size: 1.6rem;
  }
  .main-page-component .main-page-location-columns__right .main-page-location-map {
    max-width: 100%;
    margin: 0 auto;
  }
  .main-page-component .main-page-location-columns__right .main-page-location-marker-1 {
    left: 17%;
  }
  .main-page-component .main-page-location-columns__right .main-page-location-marker-2 {
    left: 45%;
  }
  .main-page-component .main-page-location-columns__right .main-page-location-marker-3 {
    left: 38%;
  }
  .main-page-component .main-page-location-columns__right .main-page-location-marker-4 {
    left: 49%;
  }
}
@media screen and (max-width: 991px) {
  .main-page-component .main-page-header-section__container--left {
    min-width: 50rem;
  }
  .main-page-component .main-page-header-section__container--right {
    align-items: flex-end;
  }
  .main-page-component .main-page-header-section__container--right img {
    max-width: 85%;
  }
  .main-page-component .main-page-location-columns__right {
    height: auto;
  }
  .main-page-component .map-marker {
    display: none;
  }
  .main-page-component .main-page-map-with-markers {
    display: inline-block;
    margin: 0 auto;
  }
  .main-page-component .main-page-location-map {
    display: none;
  }
}
@media screen and (max-width: 876px) {
  .main-page-component .main-page-header-section__container {
    padding: 10rem 2rem 10rem 2rem;
    flex-wrap: wrap;
  }
  .main-page-component .main-page-header-section__container--left {
    max-width: 100%;
    min-width: auto;
  }
  .main-page-component .main-page-header-section__container--right {
    max-width: 100%;
    padding-left: 0;
    align-items: center;
  }
}
@media screen and (max-width: 576px) {
  .main-page-component .main-page-location-columns {
    flex-wrap: wrap;
  }
  .main-page-component .main-page-location-columns__left {
    flex-direction: column;
    align-items: center;
  }
  .main-page-component .main-page-location-columns__left .location-card-component {
    width: 100%;
    max-width: unset;
  }
}
@media screen and (max-width: 576px) {
  .main-page-component .main-page-header-section__container {
    padding: 5rem 2rem 0 2rem;
  }
}
@keyframes bounce-element {
  0%,
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
  }
}
