html {
    height: 100%;
    box-sizing: border-box;
    font-size: 10px;
    background-color: transparent;
    color: white;
    margin: 0;
    padding: 0;
    font-family: "Open Sans", sans-serif;
}
body {
    box-sizing: border-box;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    position: relative;
    margin: 0;
    min-height: 100%;
    font-family: "Open Sans", sans-serif;
}
*,
*::before,
*::after {
    box-sizing: inherit;
    font-family: "Open Sans", sans-serif;
}
*:focus,
*::before:focus,
*::after:focus {
    outline: none;
}
#root {
    padding-top: 10.5rem;
}
@media screen and (max-width: 1000px) {
    #root {
        padding-top: 4rem;
    }
}
::-webkit-scrollbar {
    width: 0.8rem;
}
::-webkit-scrollbar-track {
    background: #2a325e;
}
::-webkit-scrollbar-thumb {
    background: #5667cd;
}
::-webkit-scrollbar-thumb:hover {
    background: #424f87;
}
button {
    outline: none !important;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
button:focus {
    outline: none !important;
}
input {
    outline: none !important;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}
input:focus {
    outline: none !important;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
a {
    text-decoration: none;
    color: #2a325e;
    outline: none !important;
    -webkit-transition: color 0.5s linear;
    -moz-transition: color 0.5s linear;
    -ms-transition: color 0.5s linear;
    -o-transition: color 0.5s linear;
    transition: color 0.5s linear;
}
a:hover {
    text-decoration: none;
    color: #3a4581;
    outline: none !important;
}
a:focus {
    outline: none !important;
}
a:visited {
    outline: none !important;
}
/* Built-in css created circle */
/* Use the background-color property to color it */
.circle {
    width: 1rem;
    height: 1rem;
    display: inline-block;
    border-radius: 50%;
}
/* Built-in css created cube */
/* Use the background-color property to color it */
.cube {
    width: 0.5rem;
    height: 0.5rem;
    /*Changing this will globally affect it if the project design
    most works with inline then change it to inline-block */
    display: block;
    margin: 0.3rem 0;
    -webkit-border-radius: 0.3rem;
    -moz-border-radius: 0.3rem;
    border-radius: 0.3rem;
}
/* Built-in css created arrow */
/* Use the border-color property to color it */
.arrow {
    border: solid white;
    border-width: 0 0.4rem 0.4rem 0;
    display: inline-block;
    position: absolute;
    padding: 0.4rem;
    top: 0;
    right: 0;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}
/* Built-in css created up-down arrow */
/* Use the background and border-color property to color it */
.up-down-arrow {
    width: 0.3rem;
    height: 2rem;
    background: white;
    margin: 1rem;
    position: relative;
}
.up-down-arrow::before,
.up-down-arrow::after {
    content: " ";
    position: absolute;
    left: -0.6rem;
    width: 0;
    height: 0;
    border-left: 0.7rem solid transparent;
    border-right: 0.7rem solid transparent;
}
.up-down-arrow::before {
    bottom: 0;
    border-top: 0.7rem solid white;
}
.up-down-arrow::after {
    top: -0.7rem;
    border-bottom: 0.7rem solid white;
}
/* Built-in css created close element. Displays an X */
/* Use the background and border-color property to color it */
.close {
    width: 2.5rem;
    height: 2.5rem;
    background-color: transparent;
    color: #2a325e;
    cursor: pointer;
}
.close::after {
    position: relative;
    content: "\d7";
    font-weight: bold;
    top: -0.5rem;
    left: 0;
    font-size: 4rem;
}
.threads {
    cursor: pointer;
}
.threads .thread {
    width: 2rem;
    height: 0.2rem;
    display: block;
    background-color: white;
    margin: 0.3rem 0;
    -webkit-border-radius: 1rem;
    -moz-border-radius: 1rem;
    border-radius: 1rem;
    -webkit-transition: background-color 0.2s linear;
    -moz-transition: background-color 0.2s linear;
    -ms-transition: background-color 0.2s linear;
    -o-transition: background-color 0.2s linear;
    transition: background-color 0.2s linear;
}
.threads:hover .thread {
    background-color: white;
}
.arrow-root {
    display: block;
    position: relative;
    cursor: pointer;
}
.arrow-root::after {
    content: " ";
    display: block;
    background-color: white;
    width: 2rem;
    height: 0.5rem;
    right: 0.3rem;
    top: 0.3rem;
    position: absolute;
}
.arrow-root .arrow {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
}
.arrow-root:hover::after {
    background-color: #424f87;
}
.arrow-root:hover .arrow {
    border-color: transparent #424f87 #424f87 transparent;
}
