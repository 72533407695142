.e404-wrapper {
    padding: 15rem 2rem 24rem 2rem;
}
.e404-wrapper .e404 {
    background-color: #edf6ff;
    color: #424f87;
    margin: 0 auto;
    max-width: 50rem;
    padding: 4rem;
    text-align: center;
}
.e404-wrapper .e404__title {
    font-size: 4rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 3rem;
    display: block;
}
.e404-wrapper .e404__text {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;
    text-align: left;
    max-width: 40rem;
    display: block;
}
.e404-wrapper .e404__button {
    display: block;
    margin: 0 auto;
    border: none;
    background-color: #424f87;
    color: white;
    font-size: 1.4rem;
    padding: 1.5rem 2rem;
    cursor: pointer;
    -webkit-transition: background-color 0.3s linear;
    -moz-transition: background-color 0.3s linear;
    -ms-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
    transition: background-color 0.3s linear;
}
.e404-wrapper .e404__button:hover {
    background-color: #525e9d;
}
