.video-container {
  width: 100%;
  padding: 4rem 2rem 4rem 2rem;
  background-color: #edf6ff;
}
.video-container .video-wrapper {
  margin: 0 auto;
  max-width: 100rem;
}
.video-container .video-wrapper .video {
  position: relative;
  padding-bottom: 56.25%;
}
.video-container .video-wrapper .video iframe {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
}
@media screen and (max-width: 576px) {
  .video-container {
    padding: 0;
  }
  .video-container .video-wrapper {
    max-width: 100%;
  }
}
