.lease-wrapper {
  padding: 12rem 2rem 24rem 2rem;
}
.lease-wrapper .lease {
  display: block;
  margin: 0 auto;
  background-color: #edf6ff;
  color: #424f87;
  max-width: 137rem;
}
.lease-wrapper .lease__title {
  padding: 4rem 0;
  margin: 0 7rem;
  font-size: 3rem;
  font-weight: 700;
  border-bottom: 0.1rem solid #d4e9ff;
}
.lease-wrapper .lease__desc {
  margin: 0 7rem;
  font-size: 1.6rem;
  border-bottom: 0.1rem solid #d4e9ff;
  padding-bottom: 3rem;
  text-align: justify;
}
@media only screen and (max-width: 992px) {
  .lease-wrapper .lease__desc {
    margin: 0 3rem;
  }
}
.lease-wrapper .lease__container {
  padding: 4rem 7rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.lease-wrapper .lease .card-container {
  padding: 2rem 2.5rem;
}
.lease-wrapper .lease .card-container .lease-card {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 36rem;
  flex-direction: row;
  background-color: white;
  -webkit-box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 1rem 0.1rem rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.lease-wrapper .lease .card-container .lease-card__header {
  background-color: #424f87;
  min-height: 8rem;
  width: 100%;
  padding: 2rem 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 1.6rem;
  font-weight: 600;
  text-transform: uppercase;
}
.lease-wrapper .lease .card-container .lease-card__image {
  width: 100%;
  height: auto;
  position: relative;
  text-align: center;
}
.lease-wrapper .lease .card-container .lease-card__image--element {
  max-width: 100%;
  height: auto;
}
.lease-wrapper .lease .card-container .lease-card__sub-title {
  position: absolute;
  bottom: 0.3rem;
  left: 0;
  color: white;
  font-size: 1.2rem;
  font-weight: 600;
  right: 0;
  text-transform: capitalize;
  background-color: #5667cd;
  padding: 0.6rem 3.5rem;
  -webkit-border-top-right-radius: 2rem;
  -webkit-border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  -webkit-border-top-left-radius: 2rem;
  -moz-border-radius-topright: 2rem;
  -moz-border-radius-bottomright: 0;
  -moz-border-radius-bottomleft: 0;
  -moz-border-radius-topleft: 2rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-top-left-radius: 2rem;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.lease-wrapper .lease .card-container .lease-card__body {
  padding: 2rem 3rem 1.5rem 3rem;
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: 255px;
}
.lease-wrapper .lease .card-container .lease-card__body--label {
  font-weight: 600;
  color: #2a325e;
  padding: 0.3rem 0;
  font-size: 1.4rem;
  margin: 0 0;
}
.lease-wrapper .lease .card-container .lease-card__body--data {
  font-size: 1.8rem;
  font-weight: 600;
  padding: 0.3rem 0;
  margin: 0 0;
  color: #5667cd;
}
.lease-wrapper .lease .card-container .lease-card__footer {
  border-top: 0.1rem solid #deeeff;
  padding: 1.5rem 3rem;
  color: #424f87;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
}
.lease-wrapper .lease .card-container .lease-card__footer .arrow-root {
  position: relative;
}
.lease-wrapper .lease .card-container .lease-card__footer .arrow-root::after {
  background-color: #424f87;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
.lease-wrapper .lease .card-container .lease-card__footer .arrow-root .arrow {
  -webkit-transition: border-color 0.3s linear;
  -moz-transition: border-color 0.3s linear;
  -ms-transition: border-color 0.3s linear;
  -o-transition: border-color 0.3s linear;
  transition: border-color 0.3s linear;
  border-color: #424f87 #424f87 #424f87 transparent;
  position: relative;
}
.lease-wrapper .lease .card-container .lease-card__footer .arrow-root:hover::after {
  background-color: #5667cd;
}
.lease-wrapper .lease .card-container .lease-card__footer .arrow-root:hover .arrow {
  border-color: #5667cd #5667cd #5667cd transparent;
}
@media screen and (max-width: 1417px) {
  .lease-wrapper .lease__container {
    justify-content: center;
  }
  .lease-wrapper .lease .card-container {
    max-width: 33%;
  }
  .lease-wrapper .lease .card-container .lease-card {
    max-width: unset;
    width: 100%;
  }
}
@media screen and (max-width: 1237px) {
  .lease-wrapper .lease__container {
    padding: 4rem 3rem;
  }
  .lease-wrapper .lease .card-container {
    max-width: 33%;
  }
}
@media screen and (max-width: 1150px) {
  .lease-wrapper .lease .card-container {
    max-width: 50%;
  }
}
@media screen and (max-width: 991px) {
  .lease-wrapper .lease__title {
    margin-left: 4rem;
  }
  .lease-wrapper .lease__container {
    padding: 4rem 2rem;
  }
  .lease-wrapper .lease .card-container {
    max-width: 50%;
  }
  .lease-wrapper .lease .card-container .lease-card__body {
    min-height: 14rem;
  }
}
@media screen and (max-width: 730px) {
  .lease-wrapper .lease__container {
    padding: 4rem 2rem;
  }
  .lease-wrapper .lease .card-container {
    max-width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .lease-wrapper .lease__title {
    margin-left: 2rem;
  }
  .lease-wrapper .lease__container {
    padding: 4rem 0;
  }
  .lease-wrapper .lease .card-container {
    padding: 2rem 2rem;
  }
}
@media screen and (max-width: 576px) {
  .lease-wrapper {
    padding: 6rem 0rem 16rem 0rem;
  }
}
