.about-us {
    padding: 15rem 2rem 24rem 2rem;
    color: #424f87;
}
.about-us__container {
    -webkit-box-shadow: 0 0 2rem 0.1rem rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 2rem 0.1rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 2rem 0.1rem rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #edf6ff;
    max-width: 120rem;
    margin: 0 auto;
}
.about-us__container--left {
    display: flex;
    flex-direction: column;
    padding: 4rem 7rem;
    width: 100%;
}
.about-us__container--right {
    display: flex;
    background-color: white;
    flex-direction: column;
    padding: 4rem 7rem;
    width: 100%;
    max-width: 40%;
}
.about-us__title {
    font-size: 2.7rem;
}
.about-us__title-small {
    font-size: 2rem;
    margin: 1rem 0 1rem 0;
}
.about-us__paragraph {
    font-size: 1.4rem;
    padding-bottom: 1rem;
    line-height: 2.8rem;
    text-align: justify;
}
.about-us__team {
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    position: relative;
}
.about-us__team .circle {
    background-color: #deeeff;
    position: absolute;
    left: 0;
    width: 1.5rem;
    height: 1.5rem;
    top: 1.5rem;
}
.about-us__team--text {
    padding-left: 4rem;
}
.about-us__team--name {
    margin: 0 0 0.5rem 0;
    font-size: 1.8rem;
}
.about-us__team--position {
    margin: 0;
    font-size: 1.4rem;
    color: #5667cd;
}
.about-us__timeline {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem 1rem;
    height: 7rem;
}
.about-us__timeline:first-of-type {
    margin-top: 2rem;
}
.about-us__timeline--date {
    font-size: 1.6rem;
    padding-right: 2rem;
}
.about-us__timeline--dot {
    display: inline-flex;
    align-items: center;
    position: relative;
}
.about-us__timeline--dot .circle {
    background-color: #deeeff;
    width: 2.8rem;
    height: 2.8rem;
}
.about-us__timeline--dot::after {
    position: absolute;
    top: 2.6rem;
    left: 0.9rem;
    content: " ";
    display: block;
    width: 1rem;
    height: 5rem;
    background-color: #deeeff;
}
.about-us__timeline--text {
    padding-left: 2rem;
    display: block;
    font-weight: 600;
    font-size: 1.6rem;
}
.about-us__timeline .no-next::after {
    display: none;
}
@media screen and (max-width: 1200px) {
    .about-us__container--left {
        padding: 3rem;
    }
    .about-us__container--right {
        padding: 3rem;
    }
}
@media screen and (max-width: 920px) {
    .about-us__container {
        flex-direction: column;
    }
    .about-us__container--left {
        max-width: 100%;
    }
    .about-us__container--right {
        max-width: 100%;
    }
}
@media screen and (max-width: 576px) {
    .about-us__container--left {
        padding: 3rem 1.5rem;
    }
    .about-us__container--right {
        padding: 3rem 1.5rem;
    }
    .about-us__timeline {
        height: 12rem;
    }
    .about-us__timeline:first-of-type {
        margin-top: 0;
    }
    .about-us__timeline--date {
        padding-right: 1rem;
    }
    .about-us__timeline--dot .circle {
        width: 1.5rem;
        height: 1.5rem;
    }
    .about-us__timeline--dot::after {
        top: 1.5rem;
        left: 0.6rem;
        height: 11rem;
        width: 0.3rem;
    }
    .about-us__timeline--text {
        padding-left: 1rem;
        font-size: 1.3rem;
    }
}
