.news-container {
    padding: 12rem 2rem 24rem 2rem;
}
.news-container .news {
    justify-content: flex-start;
    max-width: 120rem;
    margin: 0 auto;
    display: flex;
    -webkit-box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 1rem 0.2rem rgba(0, 0, 0, 0.1);
    flex-wrap: wrap;
}
.news-container .news__left-block {
    background-color: #edf6ff;
    color: #424f87;
    max-width: 65%;
    width: 100%;
    padding: 4rem 7rem;
    display: flex;
    flex-direction: column;
}
.news-container .news__right-block {
    background-color: white;
    max-width: 35%;
    width: 500px;
    display: flex;
}
.news-container .news__title {
    text-transform: uppercase;
    font-size: 3rem;
}
.news-container .news__title-small {
    font-size: 2.2rem;
}
.news-container .news__text {
    font-size: 1.4rem;
    line-height: 2.5rem;
    max-width: 50rem;
}
.news-container .news__url {
    padding: 4rem 0 1rem 0;
    display: flex;
    color: #424f87;
    justify-content: flex-start;
    align-items: center;
}
.news-container .news__url img {
    max-width: 2.4rem;
}
.news-container .news__url a {
    font-size: 1.4rem;
    padding-left: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.news-container .news__url a:hover {
    color: #5667cd;
}
@media screen and (max-width: 768px) {
    .news-container .news__left-block {
        padding: 4rem 2rem;
        max-width: 100%;
    }
    .news-container .news__title {
        text-align: center;
    }
    .news-container .news__text {
        max-width: 100%;
    }
    .news-container .news__right-block {
        max-width: 100%;
        width: 100%;
        justify-content: center;
    }
}
