.top-img {
    position: absolute;
    max-width: 100%;
    width: 100%;
    height: auto;
}
.locations-wrapper {
    padding: 18rem 2rem 24rem 2rem;
    background-color: #edf6ff;
}
.locations-wrapper .locations {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: nowrap;
}
.locations-wrapper .locations__left {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 50%;
    z-index: 1;
}
.locations-wrapper .locations__left .location-card-component {
    cursor: pointer;
    width: 100%;
    max-width: 50%;
}
.locations-wrapper .locations__right {
    max-width: 50%;
    display: flex;
    padding-top: 2rem;
    align-items: center;
    justify-content: flex-end;
}
.locations-wrapper .locations__right .map {
    max-width: 100%;
    height: auto;
}
@media screen and (max-width: 1647px) {
    .locations-wrapper .locations__left {
        min-width: 80rem;
    }
}
@media screen and (max-width: 1500px) {
    .locations-wrapper .locations__left {
        min-width: 60rem;
        max-width: 60rem;
    }
    .locations-wrapper .locations__left .location-card-component {
        width: 100%;
        max-width: 30rem;
    }
    .locations-wrapper .locations__left .location-card-component .location-card__image-container {
        width: 100%;
        min-width: unset;
    }
    .locations-wrapper .locations__left .location-card-component .location-card__body {
        width: 100%;
        min-height: 13.6rem;
        min-width: unset;
    }
    .locations-wrapper .locations__right {
        max-width: unset;
    }
}
@media screen and (max-width: 1200px) {
    .locations-wrapper .locations {
        flex-wrap: wrap;
    }
    .locations-wrapper .locations__left {
        min-width: unset;
        max-width: 100%;
    }
    .locations-wrapper .locations__left .location-card-component {
        max-width: 50%;
    }
    .locations-wrapper .locations__left .location-card-component .location-card__image {
        width: 100%;
        height: 50%;
    }
    .locations-wrapper .locations__right {
        min-width: unset;
        max-width: 100%;
    }
}
@media screen and (max-width: 630px) {
    .locations-wrapper .locations__left {
        justify-content: center;
    }
    .locations-wrapper .locations__left .location-card-component {
        max-width: 100%;
    }
}
@media screen and (max-width: 991px) {
    .locations-wrapper {
        padding: 5rem 0rem 22rem 0rem;
    }
}
