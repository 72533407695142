.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 4rem;
  background-color: #424f87;
  position: fixed;
  z-index: 500;
  top: 0;
}
.navbar__group-name {
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  position: fixed;
  top: 0.5rem;
  left: 2rem;
  display: none;
}
.navbar__wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.navbar__brand {
  padding-left: 3rem;
  cursor: pointer;
}
.navbar__brand--logo {
  max-width: 20rem;
  width: 100%;
  height: auto;
}
.navbar__content {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5rem;
}
.navbar__content--route {
  padding: 2rem 3rem;
  font-size: 1.6rem;
  cursor: pointer;
  background-color: transparent;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s linear;
  -moz-transition: background-color 0.3s linear;
  -ms-transition: background-color 0.3s linear;
  -o-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear;
}
.navbar__content--route:hover {
  background-color: #3f4b80;
}
.navbar__content .dark-background-nav {
  background-color: #3f4b80;
}
.navbar__content .dark-background-nav:hover {
  background-color: #3a4576;
}
.navbar .threads {
  display: none;
  padding-right: 2rem;
}
.navbar .backdrop {
  display: none;
}
@media screen and (max-width: 1200px) {
  .navbar__content {
    padding-right: 0;
  }
}
@media screen and (max-width: 1000px) {
  .navbar__group-name {
    display: block;
  }
  .navbar__wrapper {
    flex-direction: column;
    width: 0;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: white;
    z-index: 1;
    -webkit-transition: width 0.3s linear;
    -moz-transition: width 0.3s linear;
    -ms-transition: width 0.3s linear;
    -o-transition: width 0.3s linear;
    transition: width 0.3s linear;
  }
  .navbar__brand {
    padding-left: 0;
  }
  .navbar__content--route {
    opacity: 0;
  }
  .navbar .threads {
    display: block;
    position: absolute;
    right: 0;
    top: 1rem;
  }
  .navbar .backdrop {
    display: block;
    position: fixed;
    z-index: 2;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    left: 100%;
    -webkit-transition: left 0.3s linear;
    -moz-transition: left 0.3s linear;
    -ms-transition: left 0.3s linear;
    -o-transition: left 0.3s linear;
    transition: left 0.3s linear;
    cursor: pointer;
  }
  .navbar-open {
    z-index: 100;
  }
  .navbar-open .navbar__wrapper {
    flex-direction: column;
    width: 25rem;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    background-image: -moz-linear-gradient(45deg, #424f87 0%, #424f87 75%, #2a325e 100%);
    background-image: -webkit-gradient(45deg, #424f87 0%, #424f87 75%, #2a325e 100%);
    background-image: -webkit-linear-gradient(45deg, #424f87 0%, #424f87 75%, #2a325e 100%);
    background-image: -o-linear-gradient(45deg, #424f87 0%, #424f87 75%, #2a325e 100%);
    background-image: -ms-linear-gradient(45deg, #424f87 0%, #424f87 75%, #2a325e 100%);
    background-image: linear-gradient(45deg, #424f87 0%, #424f87 75%, #2a325e 100%);
  }
  .navbar-open .navbar__content {
    height: 100%;
    position: relative;
    width: 100%;
    padding-right: 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 4rem;
    background-color: transparent;
  }
  .navbar-open .navbar__content--route {
    width: 100%;
    opacity: 1;
    -webkit-animation: fade-in 0.6s linear;
    -moz-animation: fade-in 0.6s linear;
    -ms-animation: fade-in 0.6s linear;
    -o-animation: fade-in 0.6s linear;
    animation: fade-in 0.6s linear;
  }
  .navbar-open .navbar__content--route:hover {
    background-color: #313b65;
  }
  .navbar-open .threads {
    position: absolute;
    z-index: 1001;
  }
  .navbar-open .backdrop {
    display: block;
    z-index: 1000;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.5);
    left: 25rem;
    cursor: pointer;
  }
}
@media screen and (max-height: 568px) {
  .navbar-open .navbar__content {
    padding-top: 0;
  }
  .navbar-open .navbar__content--route {
    padding: 1.5rem 3rem;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
    margin-left: -30rem;
  }
  50% {
    opacity: 0;
    margin-left: -30rem;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}
