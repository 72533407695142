.map {
    width: calc(100% + 5rem);
    min-height: 40rem;
    padding-top: 4rem;
    margin-bottom: -4rem;
    margin-left: -2.5rem;
    margin-right: -2.5rem;
}
.map__label--marker {
    background-color: white;
    color: #424f87;
    font-weight: 600;
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
    margin-left: -7.5rem !important;
    cursor: default !important;
    display: flex;
}
.map__label--marker .address {
    display: block;
}
.map__label--marker .marker-icon::before {
    margin-left: 0;
    margin-right: 0.5rem;
    font-size: 1.4rem;
    font-weight: 400;
}
@media screen and (max-width: 576px) {
    .map {
        width: calc(100% + 1.4rem);
        margin-left: -0.7rem;
        margin-right: 0;
    }
}
