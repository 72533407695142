.references {
    padding: 12rem 2rem 8rem 2rem;
}
.references__title {
    font-size: 3rem;
    color: #424f87;
    text-align: center;
    font-weight: 600;
}
.references__container {
    max-width: 120rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 auto;
}
.references__image-container {
    -webkit-box-shadow: 0.5rem 0.5rem 1rem 0.3rem rgba(179, 208, 234, 0.4);
    -moz-box-shadow: 0.5rem 0.5rem 1rem 0.3rem rgba(179, 208, 234, 0.4);
    box-shadow: 0.5rem 0.5rem 1rem 0.3rem rgba(179, 208, 234, 0.4);
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    display: flex;
    width: 30%;
}
.references__image-container .image {
    max-width: 100%;
    height: auto;
}
@media screen and (max-width: 991px) {
    .references__image-container {
        width: 47.5%;
    }
}
@media screen and (max-width: 576px) {
    .references__image-container {
        width: 100%;
    }
}
